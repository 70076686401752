import { FunctionComponent, useState } from "react";
import { formatPrice } from "_utils";
import { useTranslation } from "react-i18next";
import { Quote } from "_api";
import { atomToken } from "_atoms";
import { useRecoilState } from "recoil";
import { toast } from "react-toastify";
import "./table.scss";

type QuoteTableProps = {
  quotes: Quote[];
  setClientInfoModal: (quote: Quote | null) => void;
  setPointOfSaleModal: (quote: Quote | null) => void;
  setCommentaryModal: (quote: Quote | null) => void;
  onSelectedCUNOChange: (newCUNO: string) => void;
};

type SortParams = {
  column: string;
  direction: "asc" | "desc";
};

const QuoteTable: FunctionComponent<QuoteTableProps> = ({
  quotes,
  setClientInfoModal,
  setPointOfSaleModal,
  onSelectedCUNOChange,
}) => {
  const { t } = useTranslation();

  const [token] = useRecoilState(atomToken);
  const [sortParams, setSortParams] = useState<SortParams>({
    column: "quoteNumber",
    direction: "asc",
  });

  const telechargePDF = (pdfURL: string, pdfName: string) => {
    const pdfURLWithToken = `${pdfURL}?token=${token}`;
    fetch(pdfURLWithToken)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 412) {
            toast.error(t("toast_error_while_decrypting_the_param_parameter"));
          } else if (response.status === 413) {
            toast.error(t("toast_error_retrieving_the_associated_pdf"));
          } else {
            toast.error(t("toast_get_data_error"));
          }
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = pdfName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(() => {
        toast.error(t("toast_get_data_error"));
      });
  };

  const handleSort = (column: string) => {
    const isAsc =
      sortParams.column === column && sortParams.direction === "asc";
    setSortParams({ column, direction: isAsc ? "desc" : "asc" });
  };

  const sortedQuotes = quotes.sort((a, b) => {
    const isAsc = sortParams.direction === "asc" ? 1 : -1;
    switch (sortParams.column) {
      case "quoteNumber":
        return isAsc * a.ORNO.localeCompare(b.ORNO);
      case "clientName":
        return (
          isAsc * a.infoClient.lastName.localeCompare(b.infoClient.lastName)
        );
      case "brand":
        return isAsc * a.brand.localeCompare(b.brand);
      case "model":
        return isAsc * a.model.localeCompare(b.model);
      case "year":
        return isAsc * (parseInt(b.year, 10) - parseInt(a.year, 10));
      case "amount":
        return isAsc * (b.amount - a.amount);
      case "creationDate":
        return (
          isAsc *
          (new Date(a.creationDate).getTime() -
            new Date(b.creationDate).getTime())
        );
      case "validityDate":
        return (
          isAsc *
          (new Date(a.validityDate).getTime() -
            new Date(b.validityDate).getTime())
        );
      case "dealerNameLocation":
        return (
          isAsc * a.selectedPointOfSale.localeCompare(b.selectedPointOfSale)
        );
      default:
        return 0;
    }
  });

  const handleChangePointOfSale = (orderNumber: string) => {
    const selectedOrder = quotes.find(quote => quote.ORNO === orderNumber);  
    if (selectedOrder) {
        onSelectedCUNOChange(selectedOrder.CUNO); 
        setPointOfSaleModal(selectedOrder);
    } else {
        console.error('Order not found');
    }
  };


  return (
    <div className="page-container">
      <div className="table">
        <div className="table__header">
          <div className="table__sticky">
            <div className="table__group">
              <div className="table__title status">{t("status")}</div>
              <div className="table__title client-doc">{t("client_doc")}</div>
              <div
                className={`table__title quote-number ${
                  sortParams.column === "quoteNumber"
                    ? sortParams.direction === "asc"
                      ? "table__title--asc"
                      : "table__title--desc"
                    : "table__title--sortable"
                }`}
                onClick={() => handleSort("quoteNumber")}
              >
                {t("quote_number")}
              </div>
            </div>
          </div>
          <div className="table__group">
            <div className="table__title info-client">{t("quote_ref")}</div>
            <div
              className={`table__title client-name ${
                sortParams.column === "clientName"
                  ? sortParams.direction === "asc"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : "table__title--sortable"
              }`}
              onClick={() => handleSort("clientName")}
            >
              {t("client_name")}
            </div>
            <div
              className={`table__title brand ${
                sortParams.column === "brand"
                  ? sortParams.direction === "asc"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : "table__title--sortable"
              }`}
              onClick={() => handleSort("brand")}
            >
              {t("brand")}
            </div>
            <div
              className={`table__title model ${
                sortParams.column === "model"
                  ? sortParams.direction === "asc"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : "table__title--sortable"
              }`}
              onClick={() => handleSort("model")}
            >
              {t("model")}
            </div>
            <div
              className={`table__title year ${
                sortParams.column === "year"
                  ? sortParams.direction === "asc"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : "table__title--sortable"
              }`}
              onClick={() => handleSort("year")}
            >
              {t("year")}
            </div>
            <div
              className={`table__title price ${
                sortParams.column === "amount"
                  ? sortParams.direction === "asc"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : "table__title--sortable"
              }`}
              onClick={() => handleSort("amount")}
            >
              {t("amount")}
            </div>
            <div
              className={`table__title creation-date ${
                sortParams.column === "creationDate"
                  ? sortParams.direction === "asc"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : "table__title--sortable"
              }`}
              onClick={() => handleSort("creationDate")}
            >
              {t("creation_date")}
            </div>
            <div
              className={`table__title validity-date ${
                sortParams.column === "validityDate"
                  ? sortParams.direction === "asc"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : "table__title--sortable"
              }`}
              onClick={() => handleSort("validityDate")}
            >
              {t("validity_date")}
            </div>
            <div className="table__title client-phone">{t("client_phone")}</div>
            <div
              className={`table__title dealer-name ${
                sortParams.column === "dealerNameLocation"
                  ? sortParams.direction === "asc"
                    ? "table__title--asc"
                    : "table__title--desc"
                  : "table__title--sortable"
              }`}
              onClick={() => handleSort("dealerNameLocation")}
            >
              {t("dealer_name_location")}
            </div>
          </div>
        </div>

        {sortedQuotes.map((quote) => {
          let statusColor;
          if (quote.statusStk == "Commande_a_trigano") {
            statusColor = "green";
          } else if (quote.statusStk == "devis_en_cours") {
            statusColor = "orange";
          } else {
            statusColor = "";
          }

          return (
            <div className="table__line" key={`quote_${quote.id}`}>
              <div className="table__sticky">
                <div className="table__group">
                  <div className="table__item status">
                    <span className={`dot ${statusColor}`}></span>
                  </div>
                  <div className="table__item client-doc">
                    {
                      <span
                        className="pdf"
                        onClick={() =>
                          telechargePDF(
                            quote.clientDoc.replace(/\.pdf$/, ""),
                            "Devis-" + quote.ORNO + ".pdf"
                          )
                        }
                      ></span>
                    }
                  </div>
                  <div className="table__item quote-number">{quote.ORNO}</div>
                </div>
              </div>
              <div className="table__group">
                <div className="table__item info-client"> {quote.id}</div>
                <div className="table__item client-name">
                  {quote.infoClient.lastName}
                  <span
                    className="edit"
                    onClick={() => setClientInfoModal(quote)}
                  ></span>
                </div>
                <div className="table__item brand">{quote.brand}</div>
                <div className="table__item model">{quote.model}</div>
                <div className="table__item year">{quote.year}</div>
                <div className="table__item price">
                  {formatPrice(quote.amount)}
                </div>
                <div className="table__item creation-date">
                  {new Date(quote.creationDate).toLocaleDateString()}
                </div>
                <div className="table__item validity-date">
                  {new Date(quote.validityDate).toLocaleDateString()}
                </div>
                <div className="table__item client-phone">
                  {quote.infoClient.clientPhone}
                  <span
                    className="edit"
                    onClick={() => setClientInfoModal(quote)}
                  ></span>
                </div>
                <div className="table__item dealer-name">
                  {quote.point_of_sale.name}
                  {
                    <span
                      className="edit"
                      onClick={() => handleChangePointOfSale(quote.ORNO)}
                    ></span>
                  }
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuoteTable;
